.admin-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101 !important;

  .modal-body {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 5px black;

    .modal-header {
      position: relative;
      background-color: white;
      padding: 15px;
      border: 1px solid #eee;

      .close-button {
        position: absolute;
        right: 0;
        top: 0;
        color: gray;
      }
    }
    
    .modal-container {
      position: relative;
      background-color: white;
      padding: 15px;
      min-width: 300px;

      .modal-content {
        overflow-y: auto;
        max-height: 70vh;
      }
    }
  }
}