.suggest-modal {
  .single-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 400px;
    border-bottom: 1px solid #f0f0f0;
    .add-wrapper {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      align-items: center;
      justify-content: center;
      background-color: #0003;
    }
    &:hover .add-wrapper {
      display: flex;
    }
  }
  .photo {
    width: 70px;
    height: 70px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}