
.shared-header {
  display: flex;
  justify-content: center;
  color: var(--color-sea);
  font-size: 25px;
  font-weight: bold;
  
  .shared-url {
    text-decoration: underline;

    svg {
      margin-left: 15px;
      color: var(--color-light);
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 15px;
    svg {
      width: 15px;
      height: 15px;
      margin-left: 5px;
    }
  }
  @media screen and (max-width: 390px) {
    font-size: 13px;
  }
}

.share-status {
  margin: 10px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;

  .user-name {
    color: var(--fg);
    font-size: 20pt;
    font-weight: bold;
  }

  .follow-button {
    width: 250px;
    padding: 10px 0;
    font-size: 16pt;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    border: 1px solid;
    border-radius: 8px;;
    cursor: pointer;
  }
}