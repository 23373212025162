.card-container {
  position: relative;

  .product-img {
    position: relative;
    // height: 250px;
    aspect-ratio: 1;
    border: 2px solid var(--color-blue);
    overflow: hidden;
    border-radius: 10px;

    &:hover img { transform: scale(1.1); }

    .order-status-badge{
      position:absolute;
      right:-50px;
      top:30px;
      width:200px;
      height:40px;
      display:flex;
      justify-content:center;
      align-items:center;
      color:#fff;
      background-color:var(--color-indigo);
      box-shadow:0 0 5px 1px #000;
      -webkit-transform:rotate(45deg);
      transform:rotate(45deg);

      &.color-placed { background-color:var(--color-red) }
      &.color-shipped { background-color:var(--color-green) }
    }
  }

  .item-name, .item-price {
    color: var(--color-sea);
    font-weight: bold;
    font-size: 15px;
    margin: 4px auto 0 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &.item-price {
      margin-right: 0;
      color: var(--color-green);
    }
  }
  
  .insert-link, .close-link {
    svg { color: var(--color-light); }

    &:hover svg { opacity: 0.7 };
    &.insert-link {
      svg { transform: rotate(-45deg); }
    }
  }
  
  .status-badge {
    position: absolute;
    right: -50px;
    top: 30px;
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-light);
    background-color: var(--color-indigo);
    box-shadow: 0px 0px 5px 1px var(--color-dark);
    transform: rotate(45deg);

    &.color-placed { background-color: var(--color-red); }
    &.color-shipped { background-color: var(--color-green); }
  }

  .thumb, .user-name {
    position: absolute;
    padding: 3px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 15px;
    color: var(--color-light);

    &.user-name {
      top: 5px;
      right: 10px;
      background-color: #777;
    }

    &.thumb {
      background-color: var(--color-sea);
      bottom: 5px;
    }
    &.thumb-up { left: 10px; }
    &.thumb-down { right: 10px; }
  }

  .card-footer {
    margin-top: 5px;
    @media screen and (max-width: 768px) {
      margin-top: 10px;
      gap: 10px;
    }
  }
}


.more-btn {
  background-color: var(--color-opacity) !important;
  color: var(--color-light) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  outline: none !important;
  span {
    text-transform: capitalize;
    font-size: 30px !important;
    font-weight: bold !important;
  }
  
  svg {
    width: 40px;
    height: 40px;
  }
}

.shop-item.shared .product-img, .shop-item.purchased .product-img {
  border-width: 15px;
  border-radius: 15px;
}
.shop-item.purchased .product-img {
  border-color: var(--color-green);
}
.shop-item.shared .product-img {
  border-color: var(--color-blue);
}
.shop-item.shared.purchased .product-img {
  background: linear-gradient(var(--color-dark2), var(--color-dark2)) padding-box, linear-gradient(to bottom, var(--color-green), var(--color-blue)) border-box;
  border-color: transparent;
}