#pay-now-element {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;

  &:hover {
    filter: contrast(115%);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 50%;
    
    &:before, &:after {
      border-radius: 50%;
      position: absolute;
      content: '';
    }
    &:before {
      width: 10.4px;
      height: 20.4px;
      background: #5469d4;
      border-radius: 20.4px 0 0 20.4px;
      top: -0.2px;
      left: -0.2px;
      -webkit-transform-origin: 10.4px 10.2px;
      transform-origin: 10.4px 10.2px;
      -webkit-animation: loading 2s infinite ease 1.5s;
      animation: loading 2s infinite ease 1.5s;
    }
    
    &:after {
      width: 10.4px;
      height: 10.2px;
      background: #5469d4;
      border-radius: 0 10.2px 10.2px 0;
      top: -0.1px;
      left: 10.2px;
      -webkit-transform-origin: 0px 10.2px;
      transform-origin: 0px 10.2px;
      -webkit-animation: loading 2s infinite ease;
      animation: loading 2s infinite ease;
    }
  }
}
  
#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

.Payment-App {
  display: flex;
  gap: 40px;
  padding: 20px;
  
  #link-authentication-element, #payment-element, #address-element, #pay-now-element {
    margin-top: 20px;
  }
    
  .payment-details {
    margin: 30px 0 20px;
    width: 350px;
    display: flex;
    flex-direction: column;
  }
  
  .payment-total-price {
    font-size: 25px;
    font-weight: bold;
  }
  
  .payment-products {
    margin-top: 10px;
    overflow-y: auto;
    height: 400px;
    flex-grow: 1;
  }
  
  .payment-product {
    margin: 10px 5px;
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .payment-product-img {
    width: 50px;
    height: 50px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  
  .payment-product-detail {
    width: 1px;
    flex-grow: 1;
  }
  .payment-product-name, .payment-product-price {
    font-size: 14px;
    font-weight: bold;
  }
  .payment-product-price {
    align-self: flex-start;
  }
  .payment-product-description {
    font-size: 13px;
  }
  
  .close-button {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--color-dark);
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 800px) {
    & {
      flex-direction: column;
      gap: 0;
      padding: 10px;
    }
    .payment-products {
      height: auto;
    }
  }
  
  @media only screen and (max-width: 600px) {
    form {
      width: 90vw;
      min-width: initial;
    }
    .payment-details {
      width: 90vw;
    }
  }
}
