
.singleshare-content {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
  width: 700px;
  max-width: 100%;
  
  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .left-bar {
    width: 300px;

    .logo-container {
      width: 100%;
      max-height: 400px;
      padding: 10px;
      border: 2px solid var(--color-blue);
      border-radius: 7px;
      display: flex;
      justify-content: center;
    }
    .photo-container {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      margin-top: 10px;

      .quickview-photo {
        width: 70px;
        height: 80px;
        padding: 3px;
        border: 1px solid var(--color-blue);
        border-radius: 4px;
        cursor: pointer;
        overflow: hidden;
        display: flex;
        align-items: center;

        &:hover img { scale: 1.2; }
      }
    }
  }

  .right-bar {
    width: 300px;
    flex-grow: 1;
    color: var(--color-light);

    .quickview-item-link {
      word-break: break-all;
      text-decoration: underline;
      margin-top: 30px !important;
      *{ color: var(--color-sea); }
    }
    .description-editor {
      width: 100%;
      textarea { color: var(--color-light) !important; }

      * { width: 100%; color: var(--color-blue) !important; }
      fieldset { border-color: var(--color-blue) !important; }
    }
    
    .size-item, .user-name {
      display: flex;
      justify-content: flex-end;
      span {
        color: color(--color-light);
        background-color: var(--color-red);
        border-radius: 15px;
        padding: 2px 10px;
      }
      &.user-name {
        justify-content: flex-start;
        span { background-color: var(--color-darkblue); }
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 350px;
  }
}