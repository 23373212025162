.admin-carts-container {
  .list-box {
    margin-top: 25;
    .card {
      margin-top: 15px;
      .card-content {
        display: flex;
        gap: 15px;

        .photo {
          width: 300px;
          height: 300px;
          display: flex;
          justify-content: center;
          overflow: hidden;

          img {
            width: 100%;
            object-fit: contain;
          }
        }
        .card-body {
          flex-grow: 1;
          width: 1px;

          .action-buttons {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
          }
          .card-name-price {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
          }
          .category {
            padding: 2px 10px;
            background-color: var(--admin-secondary);
            color: white;
            border-radius: 10px;
            font-size: 12px;
          }
          .size {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 10px;
            span {
              background-color: var(--admin-primary);
              color: white;
              padding: 3px 8px;
              border-radius: 10px;
            }
          }
          .description {
            margin-top: 10px;
            white-space: break-spaces;
          }
          .photos {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .additional-photo {
              width: 80px;
              height: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid orangered;
              overflow: hidden;

              img {
                width: 100%;
              }
            }
          }
          .keywords {
            margin-top: 15px;
            display: flex;
            gap: 10px;
            .keyword {
              padding: 2px 10px;
              background-color: var(--admin-secondary);
              color: white;
              border-radius: 10px;
              font-size: 12px;
            }
          }
          .info {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
.update-info-modal {
  width: 500px;
  max-width: 100%;

  .top-email {
    display: flex;
    justify-content: flex-end;
  }

  .content {
    .fields {
      margin-top: 10px;
      display: flex;
      gap: 10px;
      .rti--container {
        width: 100%;
        input {
          width: 100px;
        }
      }
    }
    .photos {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .photo {
        position: relative;
        width: 80px;
        height: 90px;
        border: 1px solid rgb(200, 200, 200);
        border-radius: 8px;
        overflow: hidden;

        &.logo {
          border: 2px solid #1446A0;
          &::after {
            content: 'LOGO';
            position: absolute;
            right: 5px;
            top: 5px;
            color: #1446A0;
            font-weight: bold;
            font-size: 12px;
          }
        }
        &:not(.logo) {
          cursor: pointer;
        }
        .remove-button {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
        }
        &:hover .remove-button {
          display: block;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .footer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
}