.landing-page {
  padding: 30px 0;
  text-align: center;

  hr {
    opacity: 0.2;
    margin: 80px 0;
  }
  .mt-section {
    margin-top: 40px;
  }
  .mb-section {
    margin-bottom: 20px;
  }
  
  .landing-top {
    display: flex;
    justify-content: center;
    gap: 100px;
  }
  .landing-top .landing-logo {
    max-width: 400px;
  }
  .landing-signin {
    max-width: 400px;
  }
  
  .landing-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      color: var(--color-light);
    }
    
    h2 {
      margin: 15px 0 0;
    }
    h3 {
      margin: 15px 0 0;
    }
    
    p, a {
      text-align: center;
      max-width: 550px;
      margin: 5px 0;
      text-decoration: none;
    }
  }
  
  .webstore-download {
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    border: 1px solid var(--color-light);
    white-space: nowrap;
    padding: 0 10px;
    border-radius: 7px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
    img { width: 60px; }
  }
  
  .carousel-container {
    margin-top: 20px;

    .carousel-text {
      text-align: center;
      min-height: 100px;
      max-width: 600px;
      margin: auto;
    }
    .carousel-item {
      padding: 10px;
      img {
        width: 100%;
        aspect-ratio: 1541 / 1202;
        box-shadow: 0px 0px 10px 3px var(--primary);
      }
    }
  }
  
  .video-container video {
    width: 100%;
  }
  
  .user-comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;

    .user-comment {
      position: relative;
      max-width: 600px;
      text-align: center;
      
      .avatar-container {
        position: absolute;
        right: 0;
        bottom: -30px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        box-shadow: 0 0 2px 1px var(--fg);
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .landing-signin {
      display: none;
    }
  }
}
