
.sign-in {
  padding-top: 20px;
  max-width: 400px;
  margin: auto;
  position: relative;
  color: var(--color-light);
  
  .input-label {
    text-align: center;
    margin: 10px 0;
  }
  .input-description {
    font-size: 15px;
    text-align: center;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid var(--color-light);
    border-radius: 10px;
    user-select: none;
    a { color: var(--color-light) }
  }

  .input-text input {
    height: 50px;
    width: 100%;
    border: 2px solid var(--color-light);
    border-radius: 20px;
    background-color: transparent;
    font-size: 25px;
    font-weight: bold;
    color: var(--color-light);
    text-align: center;
  }

  .input-button {
    width: 100%;
    background-color: var(--color-blue);
    border-radius: 20px;
    color: var(--color-light);
    font-size: 25px;
    font-weight: bold;
    height: 50px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;

    &:hover { opacity: 0.8; }
    &:active { opacity: 0.6; }
  }
}