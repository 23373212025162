.admin-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--admin-bg);
  .MuiAppBar-root {
    z-index: 100;
  }

  .logo-btn {
    padding: 0;
    svg {
      width: 130px;
      height: 65px;
    }
  }
  .logo-text {
    flex-grow: 1;
    margin-left: 10px;
  }
  .avatar {
    color: var(--fg);
    background-color: orangered;
    width: 34px;
    height: 34px;
    margin-left: 15px;
  }
  .container {
    flex-grow: 1;
    width: 1200px;
    max-width: 100%;
    padding: 20px;
    margin: auto;

    .pagination-wrapper {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }
}